
import { pageAllOrder } from '@/api/user/admin'
import { FetchFunction, useLoadList } from '@/utils/useLoadList'
import { ref } from 'vue'
import { OrderInfo } from 'momai'
export default {
  setup: () => {
    const orderList = ref<OrderInfo[]>([])
    const totalOrderNumber = ref(-1)
    const keyword = ref('')
    const fetch: FetchFunction = (pageData, loadEnd) => {
      pageAllOrder(pageData, keyword.value).then(({ records, total }) => {
        orderList.value.push(...records)
        totalOrderNumber.value = total
        loadEnd()
      })
    }
    return {
      keyword,
      ...useLoadList({
        fetch,
        total: totalOrderNumber,
        list: orderList
      }),
      orderList,
      formatFee: (fee: string) => {
        return (Number(fee) / 100).toFixed(2)
      }
    }
  }
}
